// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampAddressLevis = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 126.2 128.7'
    style={{ overflow: 'visible' }}
    {...props}
  >
    <RoundContainer>
      <path d='M0 45.6l1-2.4 6.7 2.6a3.3 3.3 0 01-1.1-2 3.8 3.8 0 01.2-2.1 4.3 4.3 0 013-2.8 7.8 7.8 0 014.8.6 7.7 7.7 0 014 2.9 4.3 4.3 0 01.2 4 3.7 3.7 0 01-1.4 1.8 3.4 3.4 0 01-2 .6l1.8.8-1 2.4zm16.4.7a2.8 2.8 0 000-2.3 3.2 3.2 0 00-1.8-1.6l-2-.8a3.2 3.2 0 00-2.4 0 2.8 2.8 0 00-1.6 1.6 3.3 3.3 0 00-.2 2 1.8 1.8 0 001.1 1.4l4 1.5a1.8 1.8 0 001.7-.2 3.3 3.3 0 001.2-1.6z' />
      <path d='M25.6 35.2a6 6 0 01-1.8 1.5 4.9 4.9 0 01-2 .6 6 6 0 01-2.4-.3 9 9 0 01-4.2-3.3 6 6 0 01-.9-2.2 4.8 4.8 0 01.1-2.2 6.2 6.2 0 012.9-3.6 4.9 4.9 0 012.1-.6 5.8 5.8 0 012.3.3 8.1 8.1 0 012.4 1.4 8.5 8.5 0 011.8 2 5.8 5.8 0 01.9 2.1 4.8 4.8 0 01-.1 2.2 6 6 0 01-1 2zM24 33.8a3 3 0 00.7-2.1 3.4 3.4 0 00-1.4-2.2l-1.6-1.3a3.3 3.3 0 00-2.4-.8 3.2 3.2 0 00-2.7 3.3A3.3 3.3 0 0018 33l1.7 1.2a3.4 3.4 0 002.4.9 2.9 2.9 0 002-1.2z' />
      <path d='M36.5 22.4a4.4 4.4 0 010 1 2.9 2.9 0 010 1 3.3 3.3 0 01-.6 1 4.4 4.4 0 01-1 .9 3.7 3.7 0 01-3.1.7 5.2 5.2 0 01-2.9-2.3l-4.5-6.3 2-1.5 4.4 6.1q1.7 2.3 3.6 1a3.8 3.8 0 00.7-.7 2.6 2.6 0 00.5-.8 2 2 0 000-.8 1.9 1.9 0 00-.3-1L30.5 14l2.1-1.5 7.2 10-2.1 1.5z' />
      <path d='M46.2 19.9a2.6 2.6 0 01-2.1 0 2.6 2.6 0 01-1.3-1.5L37.3 4.5l2.4-.9 5.7 14.3 1.5-.6.8 2z' />
      <path d='M52.5 18.2A1.7 1.7 0 0151 18a1.6 1.6 0 01-.7-1v-.3a1.6 1.6 0 010-1.3 1.7 1.7 0 011.3-.7 1.7 1.7 0 011.3.1 1.7 1.7 0 01.7 1v.4a1.6 1.6 0 010 1.2 1.7 1.7 0 01-1.2.8z' />
      <path d='M72.7 14.6h-.1a3.1 3.1 0 01-1.6 1.8 5.2 5.2 0 01-3 .6 6.7 6.7 0 01-2.8-.8 6.1 6.1 0 01-2-1.7 7.7 7.7 0 01-1.2-2.8 12.2 12.2 0 01-.2-3.7 11.5 11.5 0 01.8-3.6 7.8 7.8 0 011.6-2.6A6.2 6.2 0 0166.6.4a7.5 7.5 0 013-.4 6.8 6.8 0 013.7 1.3 6.6 6.6 0 012.2 2.9l-2.3 1a4.3 4.3 0 00-1.3-2 4.2 4.2 0 00-2.5-.8 4.1 4.1 0 00-3.2 1 5 5 0 00-1.5 3.4l-.2 2.7a5 5 0 001 3.7 4.2 4.2 0 003 1.5 5.3 5.3 0 001.5-.1 3.7 3.7 0 001.2-.5 3.1 3.1 0 001-1 2.8 2.8 0 00.4-1.3v-1.3l-3.3-.2.2-2.3 6 .5-.7 8.7-2.3-.2z' />
      <path d='M86.5 19.7h-.1a4.1 4.1 0 01-.8.6 3 3 0 01-1 .4 3.4 3.4 0 01-1 0 4.4 4.4 0 01-1.3-.3A3.7 3.7 0 0180 18a5.3 5.3 0 01.5-3.6l3.2-7.2 2.3 1-3 7c-.8 1.7-.5 2.9 1 3.5a3.8 3.8 0 00.9.3 2.8 2.8 0 001 0 2 2 0 00.7-.4 2 2 0 00.6-.7l3.4-7.5 2.3 1-5 11.2-2.4-1z' />
      <path d='M98.7 15l2 1.4-7 10-2.2-1.4zm2.3-1a1.6 1.6 0 01-.7-1 1.4 1.4 0 01.2-1l.3-.4a1.3 1.3 0 01.8-.6 1.6 1.6 0 011.2.4 1.5 1.5 0 01.7 1 1.3 1.3 0 01-.2 1l-.3.3a1.4 1.4 0 01-.8.6 1.6 1.6 0 01-1.2-.4z' />
      <path d='M98.9 31a2.6 2.6 0 01-1-1.8 2.6 2.6 0 01.8-1.8L109 16.5l1.9 1.8-10.6 11.1 1.2 1.2-1.4 1.5z' />
      <path d='M103.6 36.4a2.6 2.6 0 01-.7-2 2.6 2.6 0 011.1-1.7l11.7-9.2 1.6 2-12.1 9.5 1 1.3-1.7 1.3z' />
      <path d='M110.4 48.2a2 2 0 01-.2-1.6 2.6 2.6 0 011-1.3v-.1a3 3 0 01-2.1-.4 4.3 4.3 0 01-1.6-1.7 4 4 0 01-.5-3.1 3.6 3.6 0 012-2.1 3.1 3.1 0 013-.1 6.8 6.8 0 012.6 3l1 2 1-.4a2.4 2.4 0 001.2-1.4 2.7 2.7 0 00-.3-2 3 3 0 00-1.2-1.4 4 4 0 00-1.6-.4l.5-2a4.9 4.9 0 012.3.7 5.5 5.5 0 012 2.3 5.5 5.5 0 01.6 3.8 4 4 0 01-2.2 2.6l-5.5 2.8.6 1.1-2 1zm-.8-5.4a3.5 3.5 0 001.3 1.6 1.7 1.7 0 001.7 0l1.5-.9-1-1.9c-.8-1.6-1.7-2.1-2.7-1.6l-.3.2a1.4 1.4 0 00-.9 1 2.5 2.5 0 00.3 1.6z' />
      <path d='M115.1 59.6v-.1a4.4 4.4 0 01-.9-.3 3 3 0 01-.8-.5 3.1 3.1 0 01-.7-1 4.1 4.1 0 01-.4-1.2 3.7 3.7 0 01.6-3.1 5.3 5.3 0 013.3-1.7l7.7-1.4.5 2.6-7.4 1.3q-2.8.5-2.4 2.8a3 3 0 00.3 1 2.4 2.4 0 00.5.7 2 2 0 001.7.5l8.1-1.4.5 2.5-12.1 2.2-.5-2.6z' />
      <path d='M114.1 66.9l12 2.1-.4 2.5-2-.3v.1a5.4 5.4 0 01.8.6 3.3 3.3 0 01.7.8 3 3 0 01.3 1 3.8 3.8 0 010 1.2 4.2 4.2 0 01-1 2.1 3.6 3.6 0 01-2.1 1v.2a3.6 3.6 0 011.5 1.5 3.9 3.9 0 01.3 2.5 3.5 3.5 0 01-1.7 2.6 5.4 5.4 0 01-3.7.4l-7.7-1.4.5-2.5 7.4 1.3a3.5 3.5 0 002.2-.1 2 2 0 001-1.6 3.5 3.5 0 000-1 2.5 2.5 0 00-.3-.8 1.8 1.8 0 00-.5-.7 2 2 0 00-1-.4l-8-1.4.4-2.6 7.4 1.3c1.9.4 3-.2 3.2-1.7a4.2 4.2 0 000-.9 3 3 0 00-.2-.9 2 2 0 00-.6-.6 1.9 1.9 0 00-.9-.4l-8-1.5z' />
      <path d='M106 91.6a5.8 5.8 0 011.7-1.8 5 5 0 012-.8 5.8 5.8 0 012.4.2 8.3 8.3 0 012.4 1 8.4 8.4 0 012 1.8 5.8 5.8 0 011.1 2 4.8 4.8 0 01.1 2.2 5.8 5.8 0 01-.9 2.3 5.5 5.5 0 01-1.7 1.7 4.6 4.6 0 01-2 .7 5.7 5.7 0 01-2.2-.1 8 8 0 01-2.1-1l-.9-.6 4.6-7-.3-.2a3.3 3.3 0 00-2.4-.5 3.1 3.1 0 00-2 1.5 3.6 3.6 0 00-.6 1.9 4.4 4.4 0 00.4 1.8l-2 .5a4.9 4.9 0 01-.5-2.7 6.5 6.5 0 011-3zm9.2 5.8a3.2 3.2 0 00.5-1.1 2.6 2.6 0 000-1.2 3 3 0 00-.6-1 4 4 0 00-1-1l-3.2 4.8.2.1a3.4 3.4 0 002.3.6 2.5 2.5 0 001.8-1.2z' />
      <path d='M87.7 109.2a6.3 6.3 0 015.4-.6c1.8.6 3.4 2.2 4.8 4.6a12.7 12.7 0 011.4 3.4 7.5 7.5 0 01.2 3 5.8 5.8 0 01-1.1 2.5 7.1 7.1 0 01-2.2 1.9 6.5 6.5 0 01-3.6 1 6.2 6.2 0 01-3.4-1.3l1.3-2.1a4.1 4.1 0 002.1 1 3.6 3.6 0 002.4-.6 3.7 3.7 0 002-2.6 5.5 5.5 0 00-.8-3.7l-1.3-2.2a5.4 5.4 0 00-2.8-2.6 4.1 4.1 0 00-5 2.3 4.5 4.5 0 00-.2 2.4h-2.5a6.6 6.6 0 01.6-3.6 6.4 6.4 0 012.7-2.8z' />
      <path d='M74.7 114.1a5.8 5.8 0 012.3-.1 4.9 4.9 0 012 .8 6 6 0 011.6 1.8 9 9 0 011.2 5.1 6.1 6.1 0 01-.7 2.3 4.9 4.9 0 01-1.4 1.6 5.9 5.9 0 01-2.2 1 5.6 5.6 0 01-2.4.1 4.8 4.8 0 01-2-.8 5.8 5.8 0 01-1.6-1.8 9.1 9.1 0 01-1-5.1 5.9 5.9 0 01.6-2.2 4.9 4.9 0 011.4-1.7 5.8 5.8 0 012.2-1zm.4 2.1a2.9 2.9 0 00-1.8 1.3 3.4 3.4 0 00-.3 2.5l.5 2a3.3 3.3 0 001.3 2.2 2.9 2.9 0 002.2.3 2.9 2.9 0 002-1.2 3.3 3.3 0 00.2-2.6l-.4-2a3.4 3.4 0 00-1.4-2.2 2.8 2.8 0 00-2.3-.3z' />
      <path d='M58.1 117.3h.1a4.5 4.5 0 01.5-.8 2.9 2.9 0 01.8-.7 3.1 3.1 0 011-.4 4.1 4.1 0 011.3-.1 3.7 3.7 0 012.9 1.4 5.3 5.3 0 01.8 3.6l-.6 7.8-2.6-.2.6-7.5q.2-2.8-2.2-3a4 4 0 00-1 .1 2.4 2.4 0 00-.8.3 2 2 0 00-.7.6 2 2 0 00-.3 1l-.6 8.2-2.5-.2.9-12.3 2.6.2z' />
      <path d='M48.3 113a2.7 2.7 0 011.7 1.4 2.7 2.7 0 010 2l-2.4 7.2 1.8.6-.7 2-1-.3a1.1 1.1 0 00-.9 0 1.4 1.4 0 00-.5.8l-.7 2-2.2-.8 1-3.2-2.4-.8.7-2 2.5.9 2.5-7.7-2.2-.8.7-2z' />
      <path d='M35.4 109a4.3 4.3 0 01.9-.5 2.9 2.9 0 011-.3 3.3 3.3 0 011 .1 4.2 4.2 0 011.3.6 3.7 3.7 0 011.8 2.6 5.3 5.3 0 01-1 3.6l-4.2 6.6-2.2-1.4 4-6.3q1.6-2.4-.5-3.7a3.5 3.5 0 00-.8-.4 2.7 2.7 0 00-1-.1 2.2 2.2 0 00-.7.2 2 2 0 00-.7.7l-4.4 6.9-2.2-1.4 6.6-10.3 2.2 1.4z' />
      <path d='M31 103.2l-9 8.5-1.8-2 1.8-1.5v-.1a3.4 3.4 0 01-2 .3 3.4 3.4 0 01-2.1-1.1l-.5-.5 1.8-1.8.7.8a4.7 4.7 0 002 1.3 1.6 1.6 0 001.6-.4l5.7-5.4z' />
      <path d='M22 92a6 6 0 011 2.2 4.9 4.9 0 01-.2 2.2 5.9 5.9 0 01-1 2 8.4 8.4 0 01-2 1.8 8.4 8.4 0 01-2.5 1 5.8 5.8 0 01-2.3.2 4.8 4.8 0 01-2-.8 5.9 5.9 0 01-1.7-1.7 5.7 5.7 0 01-.9-2.3 4.6 4.6 0 01.2-2.1 5.6 5.6 0 011-2 8.3 8.3 0 011.8-1.5l.9-.5 4.5 7 .4-.1a3.2 3.2 0 001.5-2A3.1 3.1 0 0020 93a3.4 3.4 0 00-1.4-1.3 4.5 4.5 0 00-1.9-.4l.5-2.1a4.8 4.8 0 012.6.7A6.3 6.3 0 0122 92zm-9 5.8a3.2 3.2 0 00.8 1 2.7 2.7 0 001 .4 2.8 2.8 0 001.2 0 3.6 3.6 0 001.2-.5l.2-.1-3.1-4.9-.2.1a3.4 3.4 0 00-1.5 1.9 2.5 2.5 0 00.3 2.1z' />
      <path d='M105.3 103.2l1.8 1.9-4.7 4.5-1.8-2z' />
      <path d='M8 84.6A58.9 58.9 0 015 66c0-2 0-3.9.3-5.8l2.2.2a57 57 0 00-.3 5.6 56.8 56.8 0 002.9 18z' />
    </RoundContainer>

    <path d='M47.2 61.3v-2.7h5.5V43.8h-.2l-4.6 5-2-1.8 5-5.5h4.9v17.1h4.8v2.7z' />
    <path d='M78.4 61.3H65v-3l6.3-5.7a13.8 13.8 0 002.3-2.6 4.7 4.7 0 00.8-2.6V47a3 3 0 00-.8-2.2 3.3 3.3 0 00-2.4-.8 3.9 3.9 0 00-1.4.2 3.2 3.2 0 00-1 .7 3.7 3.7 0 00-.8 1 5.7 5.7 0 00-.5 1.2L64.7 46a8.2 8.2 0 01.9-1.8 6.4 6.4 0 011.3-1.6 6.3 6.3 0 012-1 8.6 8.6 0 015.3 0 5.5 5.5 0 012 1.2 5.3 5.3 0 011.2 1.9 6.5 6.5 0 01.4 2.3 6.4 6.4 0 01-.3 2.2 7.6 7.6 0 01-1 2 12.7 12.7 0 01-1.5 1.7L73 54.6l-4.6 4h10z' />
    <path d='M53.2 90a7 7 0 01-3.2-.7 5.9 5.9 0 01-2.2-2 9 9 0 01-1.3-3.2 22 22 0 010-8.7 9.2 9.2 0 011.3-3.2 6 6 0 012.2-2 7 7 0 013.2-.7 6 6 0 015.4 2.7 13.9 13.9 0 011.8 7.6c0 3.2-.6 5.7-1.8 7.5a6 6 0 01-5.4 2.7zm0-2.7a3.4 3.4 0 001.7-.4 3.2 3.2 0 001.2-1.2 5.6 5.6 0 00.6-1.8 13.2 13.2 0 00.2-2.4V78a13 13 0 00-.2-2.4 5.6 5.6 0 00-.6-1.8 3.3 3.3 0 00-1.2-1.2 3.5 3.5 0 00-1.7-.4 3.6 3.6 0 00-1.8.4 3.2 3.2 0 00-1.1 1.2 5.3 5.3 0 00-.7 1.8 12.2 12.2 0 00-.2 2.4v3.5a12.3 12.3 0 00.2 2.3 5.3 5.3 0 00.7 1.9 3.1 3.1 0 001.1 1.1 3.5 3.5 0 001.8.5z' />
    <path d='M71.6 90a7 7 0 01-3.2-.7 6 6 0 01-2.3-2 9.3 9.3 0 01-1.2-3.2 22 22 0 010-8.7 9.4 9.4 0 011.3-3.2 6 6 0 012.2-2 7 7 0 013.2-.7 6 6 0 015.4 2.7q1.8 2.7 1.8 7.6c0 3.2-.6 5.7-1.8 7.5a6 6 0 01-5.4 2.7zm0-2.7a3.4 3.4 0 001.7-.4 3.2 3.2 0 001.2-1.2 5.6 5.6 0 00.7-1.8 13.2 13.2 0 00.2-2.4V78a13 13 0 00-.2-2.4 5.6 5.6 0 00-.7-1.8 3.3 3.3 0 00-1.2-1.2 4 4 0 00-3.5 0 3.2 3.2 0 00-1.1 1.2 5.6 5.6 0 00-.7 1.9 13 13 0 00-.2 2.3v3.5a13.2 13.2 0 00.2 2.4 5.6 5.6 0 00.7 1.8 3.1 3.1 0 001.1 1.2 3.4 3.4 0 001.8.4z' />
  </svg>
);

export default StampAddressLevis;
