// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampAddressQuebec = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 125.7 132.2'
    style={{ overflow: 'visible' }}
    {...props}
  >
    <RoundContainer>
      <path d='M0 44.4l1-2.3 6.7 3V45a3.3 3.3 0 01-1-2 3.7 3.7 0 01.3-2.2 4.3 4.3 0 013-2.6 7.8 7.8 0 014.8.8 7.8 7.8 0 013.8 3.1 4.3 4.3 0 010 4 3.7 3.7 0 01-1.4 1.7 3.4 3.4 0 01-2 .5v.1l1.8.9-1.1 2.3zm16.4 1.5a2.8 2.8 0 000-2.3 3.2 3.2 0 00-1.7-1.7l-2-.8a3.2 3.2 0 00-2.3-.3 2.9 2.9 0 00-1.7 1.6 3.4 3.4 0 00-.3 2 1.8 1.8 0 001 1.4l4 1.7a1.8 1.8 0 001.6 0 3.3 3.3 0 001.4-1.6z' />
      <path d='M25.9 34.4A5.8 5.8 0 0124 36a4.9 4.9 0 01-2.1.5 6 6 0 01-2.3-.5 8.8 8.8 0 01-4-3.4 6 6 0 01-.8-2.2A5 5 0 0115 28a6.2 6.2 0 013-3.5A4.8 4.8 0 0120 24a5.9 5.9 0 012.3.4 8 8 0 012.3 1.5 8 8 0 011.8 2 6 6 0 01.8 2.2 4.8 4.8 0 01-.2 2.2 5.8 5.8 0 01-1.2 2zM24.3 33a2.9 2.9 0 00.7-2.1 3.3 3.3 0 00-1.3-2.2l-1.5-1.4a3.3 3.3 0 00-2.4-1 2.8 2.8 0 00-2 1 2.9 2.9 0 00-.8 2.3 3.3 3.3 0 001.3 2.2L20 33a3.3 3.3 0 002.3 1 3 3 0 002-1.1z' />
      <path d='M37.5 21.4v.1a4.4 4.4 0 010 1 3.1 3.1 0 01-.1 1 3.5 3.5 0 01-.6 1 4.4 4.4 0 01-1 .9 3.7 3.7 0 01-3.1.6 5.3 5.3 0 01-2.8-2.5L25.7 17l2.1-1.5 4 6.3q1.5 2.4 3.5 1.1a3.8 3.8 0 00.8-.6 2.5 2.5 0 00.5-.8 2 2 0 00.1-.9 1.8 1.8 0 00-.3-.9L32 12.8l2.2-1.4 6.6 10.4-2.2 1.4z' />
      <path d='M47.8 19a2.6 2.6 0 01-2.1 0 2.6 2.6 0 01-1.2-1.6L39.7 3.3l2.4-.9L47 17l1.6-.6.7 2z' />
      <path d='M54.7 17.4a1.7 1.7 0 01-1.4-.2 1.6 1.6 0 01-.6-1v-.4a1.6 1.6 0 01.1-1.2 1.7 1.7 0 011.3-.7 1.6 1.6 0 011.3.2 1.6 1.6 0 01.6 1v.4a1.6 1.6 0 01-.1 1.2 1.6 1.6 0 01-1.2.7z' />
      <path d='M68 16.2L70.9 0l2.6.5L71 14.4l6.6 1.2-.4 2.3z' />
      <path d='M84.8 21.2a6 6 0 01-1.9-1.5 5 5 0 01-1-2 5.8 5.8 0 010-2.3 9.2 9.2 0 012.4-4.7 5.9 5.9 0 012-1.3 4.8 4.8 0 012-.3 5.8 5.8 0 012.4.7 5.6 5.6 0 011.9 1.4 4.7 4.7 0 011 2 5.6 5.6 0 010 2.3 8.2 8.2 0 01-.9 2.2l-.4.9-7.5-3.9-.2.4a3.3 3.3 0 00-.3 2.4 3.1 3.1 0 001.7 2 3.4 3.4 0 002 .4 4.3 4.3 0 001.7-.6l.7 2a4.8 4.8 0 01-2.6.7 6.3 6.3 0 01-3-.8zm5-9.6a3.1 3.1 0 00-1.2-.4 2.6 2.6 0 00-1.1.2 2.9 2.9 0 00-1 .6 3.7 3.7 0 00-.8 1l-.1.2 5 2.6.2-.2a3.4 3.4 0 00.4-2.3 2.5 2.5 0 00-1.4-1.7z' />
      <path d='M105.4 12.9l1.9 1.8-5 5.2a3.3 3.3 0 012.2-.3 3.7 3.7 0 012 1 4.3 4.3 0 011.5 3.8 7.8 7.8 0 01-2.4 4.3 7.7 7.7 0 01-4.1 2.5 4.3 4.3 0 01-3.9-1.2 3.7 3.7 0 01-1.1-2 3.4 3.4 0 01.2-2v-.1l-1.5 1.4-1.8-1.7zm-6.8 15a2.8 2.8 0 002.1.8 3.2 3.2 0 002.2-1.1l1.5-1.5a3.2 3.2 0 001-2.3 2.8 2.8 0 00-1-2 3.4 3.4 0 00-1.9-1 1.8 1.8 0 00-1.6.5l-2.9 3a1.7 1.7 0 00-.4 1.7 3.3 3.3 0 001 1.8z' />
      <path d='M106.2 40.7a6 6 0 01-.8-2.3 4.9 4.9 0 01.3-2.2 6 6 0 011.2-2 8.2 8.2 0 012.1-1.6 8.3 8.3 0 012.5-1 6 6 0 012.3 0 4.8 4.8 0 012 .9 6.2 6.2 0 012.4 4 4.8 4.8 0 01-.3 2.2 5.9 5.9 0 01-1.2 2 8.5 8.5 0 01-2 1.6 8.2 8.2 0 01-2.6 1 6 6 0 01-2.3 0 5 5 0 01-2-.9 6 6 0 01-1.6-1.7zm1.9-1.1a2.8 2.8 0 001.7 1.4 3.3 3.3 0 002.5-.5l1.8-1a3.4 3.4 0 001.8-2 3.2 3.2 0 00-2.2-3.6 3.3 3.3 0 00-2.5.5l-1.8 1a3.3 3.3 0 00-1.7 2 2.9 2.9 0 00.4 2.2z' />
      <path d='M114.5 56a4.6 4.6 0 01-1-.3 2.9 2.9 0 01-.8-.5 3.2 3.2 0 01-.7-.9 4.2 4.2 0 01-.5-1.2 3.7 3.7 0 01.5-3.2 5.3 5.3 0 013.2-1.8l7.6-1.8.6 2.5-7.3 1.7c-1.9.5-2.6 1.4-2.3 3a4.3 4.3 0 00.4.9 2.4 2.4 0 00.6.7 1.9 1.9 0 001.7.4l8-1.8.6 2.5-12 2.8-.6-2.5z' />
      <path d='M113.4 63.9l12.3.3v2.5h-2.4v.1a3.2 3.2 0 011.6 1.1 3.4 3.4 0 01.7 2.3v.7H123v-1a4.6 4.6 0 00-.4-2.4 1.6 1.6 0 00-1.5-.8l-7.8-.2z' />
      <path d='M109 83.7a3.2 3.2 0 01-2.4-2.2 8.9 8.9 0 01.4-4.8 14 14 0 01.9-2.4 5.8 5.8 0 011-1.5 2.4 2.4 0 011.1-.7 2.6 2.6 0 011.3 0 2.1 2.1 0 011.4 1 3 3 0 01.3 1.7h.3a1.8 1.8 0 012.2-.7 1.7 1.7 0 011.2 1 4 4 0 01.3 1.6 3.8 3.8 0 012-1.2 4 4 0 012.3 0 4 4 0 012.6 2 5.2 5.2 0 010 3.8 5.9 5.9 0 01-1 2l.4.1a2 2 0 011.1.8 1.5 1.5 0 01.1 1.3l-.5 1.9-2-.6.7-2.4-.5-.1a3.7 3.7 0 01-1.7.8 4.2 4.2 0 01-2 0A3.9 3.9 0 01116 83a5.2 5.2 0 01-.1-3.8 6.2 6.2 0 01.6-1.5 2.5 2.5 0 00-.2-1 1 1 0 00-.6-.5.7.7 0 00-.8.3 3 3 0 00-.6 1.1l-.8 3a4.8 4.8 0 01-1.8 2.8 3.1 3.1 0 01-2.7.3zm.4-2.4a1.2 1.2 0 001.1-.1 3 3 0 00.8-1.6l1.2-4a1.6 1.6 0 00-1.1-1.3 1.5 1.5 0 00-1.4.3 3.4 3.4 0 00-1 1.8l-.4 1.6c-.5 2-.3 3 .8 3.3zm8.3-1.6a2.8 2.8 0 000 1.8 2 2 0 001.3 1l.5.1a1.9 1.9 0 001.6-.1 3.3 3.3 0 001-3.4 2 2 0 00-1.3-1l-.5-.1a2 2 0 00-1.6.1 2.8 2.8 0 00-1 1.6z' />
      <path d='M109 87l10.4 6.6-1.4 2.2-1.7-1a4 4 0 01.7 2 3.4 3.4 0 01-.7 2.2 3.6 3.6 0 01-2.6 1.8 5.2 5.2 0 01-3.6-1l-6.5-4.2 1.4-2.2 6.3 4c1.6 1 2.8.9 3.7-.4a4.1 4.1 0 00.4-.9 2.8 2.8 0 00.1-1 1.8 1.8 0 00-.2-.8 2 2 0 00-.7-.7l-7-4.4z' />
      <path d='M96.9 102.3a6 6 0 012-1.2 4.9 4.9 0 012.2-.2 5.9 5.9 0 012.2.8 8.5 8.5 0 012 1.8 8.3 8.3 0 011.4 2.3 5.9 5.9 0 01.5 2.2 4.7 4.7 0 01-.5 2.2 5.8 5.8 0 01-1.5 1.8 5.7 5.7 0 01-2 1.2 4.6 4.6 0 01-2.2.2 5.8 5.8 0 01-2-.8 8 8 0 01-1.8-1.6l-.7-.7 6.4-5.6-.3-.3a3.3 3.3 0 00-2.1-1.2 3.2 3.2 0 00-2.4 1 3.5 3.5 0 00-1.1 1.5 4.4 4.4 0 00-.1 2l-2.1-.2a5 5 0 01.3-2.7 6.6 6.6 0 011.8-2.5zm7 8.2a3.2 3.2 0 00.8-1 2.8 2.8 0 00.3-1.1 3 3 0 00-.2-1.2 3.9 3.9 0 00-.7-1l-.1-.2-4.3 3.7.1.2a3.5 3.5 0 002 1.3 2.5 2.5 0 002.2-.7z' />
      <path d='M83.6 113.4h.1a4.7 4.7 0 010-1 3 3 0 01.3-1 3.7 3.7 0 01.7-.9 4.5 4.5 0 011.2-.7 3.7 3.7 0 013.2 0 5.3 5.3 0 012.4 2.7l3.2 7.1-2.3 1-3.1-6.7q-1.2-2.6-3.3-1.7a3.6 3.6 0 00-.9.6 2.6 2.6 0 00-.6.7 2 2 0 00-.2.8 1.9 1.9 0 00.1 1l3.4 7.5-2.3 1-5-11.1 2.3-1.1z' />
      <path d='M77 124.1l1.8-.3.3 2-1.8.4.4 2a3.3 3.3 0 01-.4 2.4 3 3 0 01-2.2 1.2l-2 .4-.3-2 2.5-.5-.5-3-2.6.4-.4-2.1 2.6-.5-1.8-10 2.5-.4z' />
      <path d='M63.8 124.6A59 59 0 015 65.7c0-2 .1-3.9.3-5.8l2.2.2a57 57 0 00-.3 5.6 56.8 56.8 0 0056.7 56.7z' />
    </RoundContainer>

    <path d='M47 61v-2.6h5.6V43.6h-.2l-4.6 4.9-2-1.8 5-5.5h4.9v17.2h4.9V61z' />
    <path d='M65.5 61v-2.6h5.6V43.6h-.3l-4.6 4.9-2-1.8 5-5.5h5v17.2H79V61z' />
    <path d='M60 89.4H46.4v-3l6.3-5.7a13.8 13.8 0 002.3-2.6 4.6 4.6 0 00.8-2.6V75a3 3 0 00-.8-2.3 3.3 3.3 0 00-2.4-.8 3.9 3.9 0 00-1.4.2 3.2 3.2 0 00-1 .7 3.7 3.7 0 00-.8 1 6 6 0 00-.5 1.3L46.2 74a8.7 8.7 0 01.8-1.8 6.2 6.2 0 011.4-1.6 6 6 0 012-1 7.7 7.7 0 012.6-.4 7.9 7.9 0 012.7.4 5.5 5.5 0 012 1.2 5.3 5.3 0 011.2 1.9 6.8 6.8 0 01.1 4.5 7.6 7.6 0 01-1 2 12.2 12.2 0 01-1.6 1.7l-1.8 1.7-4.7 4h10z' />
    <path d='M77.4 72.4h-8.8l-.5 7h.2a8.9 8.9 0 01.7-1 4 4 0 01.8-.9A3.6 3.6 0 0171 77a5 5 0 011.5-.2 6.4 6.4 0 012.4.4 5.6 5.6 0 011.9 1.3 5.7 5.7 0 011.2 1.9 7 7 0 01.4 2.6 7.5 7.5 0 01-.4 2.7 5.9 5.9 0 01-1.4 2.1 6.4 6.4 0 01-2.2 1.4 8.2 8.2 0 01-3 .5 8.3 8.3 0 01-2.4-.3 7 7 0 01-1.8-.8 6.6 6.6 0 01-1.3-1.1 11.5 11.5 0 01-1-1.3l2.3-1.9a9.3 9.3 0 00.8 1.1 5.2 5.2 0 00.9.9 3.8 3.8 0 001.1.5 5 5 0 001.5.2 3.3 3.3 0 003.7-3.6v-.3a3.3 3.3 0 00-3.7-3.7 3.8 3.8 0 00-2 .5 6.1 6.1 0 00-1.4 1.1l-2.6-.4.7-11h11.2z' />
  </svg>
);

export default StampAddressQuebec;
