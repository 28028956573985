// vendors
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { greaterThan } from '../../utils/mediaQuery';
import CliniqueListCard from './CliniqueListCard';
import responsiveStyle from '../../utils/responsiveStyle';
import breakpoints from '../../styles/breakpoints';

const query = graphql`
  query {
    locations: allSanityLocation(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          id
          slug {
            current
          }
          address {
            city
            coordonate {
              lng
              lat
            }
            postalCode
            province
            streetName
            streetNumber
            unitNumber
            unitType
          }
          name
          phoneNumbers {
            number
            post
            type
          }
          gallery {
            ...CliniqueListCardPictureData
          }
        }
      }
    }
  }
`;

const List = styled.div`
  display: grid;
  grid-row-gap: ${rem(72)};
  grid-template-columns: 1fr;
  justify-content: space-between;
  max-width: ${rem(1560)};
  margin: auto;

  ${greaterThan(720)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${responsiveStyle('marginTop', [88, 168], breakpoints.spacings)};
  ${responsiveStyle('marginBottom', [80, 152], breakpoints.spacings)};
  ${responsiveStyle(
    'gridColumnGap',
    [40, 80, undefined, 120],
    breakpoints.spacings
  )};
`;

const CliniqueList = ({ brief, stamped }) => {
  const {
    locations: { edges: cliniques = [] },
  } = useStaticQuery(query);

  return (
    <List>
      {cliniques.map(({ node }) => (
        <CliniqueListCard
          key={node.id}
          data={node}
          brief={brief}
          stamped={stamped}
        />
      ))}
    </List>
  );
};

CliniqueList.propTypes = {
  brief: PropTypes.bool,
  stamped: PropTypes.bool,
};

CliniqueList.defaultProps = {
  brief: false,
  stamped: false,
};

export default CliniqueList;
