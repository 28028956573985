// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Container,
  Picture,
  Title,
  StyledAddress,
  PictureWrapper,
  StampWrapper,
} from './CliniqueListCard.styles';
import Button from '../Button';
import PhoneBlock from '../PhoneBlock';
import AddressBlock from '../AddressBlock';
import StampAddressQuebec from '../../images/StampAddressQuebec';
import StampAddressLevis from '../../images/StampAddressLevis';

const stampObj = {
  Québec: (props) => <StampAddressQuebec {...props} />,
  Lévis: (props) => <StampAddressLevis {...props} />,
};

const CliniqueListCard = ({ data, brief, stamped }) => {
  const source = {
    ...data.gallery[0].asset.fluid,
    alt: data.gallery[0].alt,
    sizes: `(min-width: 720px) 50vw, (min-width: 1560px) 720px, 100vw`,
  };

  const Stamp = stampObj[data.address.city];

  return (
    <Container itemscope itemtype='http://schema.org/LocalBusiness'>
      <PictureWrapper>
        <Picture fluid={source} alt={source.alt} />

        {stamped && (
          <StampWrapper>
            <Stamp />
          </StampWrapper>
        )}
      </PictureWrapper>

      <Title>{data.name}</Title>

      {!brief && (
        <StyledAddress>
          <AddressBlock data={data.address} />

          <PhoneBlock data={data.phoneNumbers} />
        </StyledAddress>
      )}

      <Button type='button' to={`/nos-cliniques/${data.slug.current}`} outlined>
        En savoir plus
      </Button>
    </Container>
  );
};

//

CliniqueListCard.propTypes = {
  brief: PropTypes.bool,
  stamped: PropTypes.bool,
  data: PropTypes.shape({
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      streetNumber: PropTypes.string.isRequired,
      streetName: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      province: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      unitNumber: PropTypes.string,
      unitType: PropTypes.string,
    }).isRequired,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['principal', 'fax']).isRequired,
        number: PropTypes.string.isRequired,
        post: PropTypes.string,
      })
    ).isRequired,
    gallery: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
        asset: PropTypes.shape({
          fluid: PropTypes.shape({
            src: PropTypes.string.isRequired,
            srcSet: PropTypes.string.isRequired,
            srcSetWebp: PropTypes.string.isRequired,
          }).isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
};

CliniqueListCard.defaultProps = {
  brief: false,
  stamped: false,
};

export default CliniqueListCard;

export const query = graphql`
  fragment CliniqueListCardPictureData on SanityFigure {
    alt
    asset {
      fluid(maxWidth: 720, maxHeight: 480) {
        src
        srcSet
        srcSetWebp
        base64
        aspectRatio
      }
    }
  }
`;
