// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const AddressBlock = ({ data }) => {
  return (
    <p itemProp='address' itemScope itemType='http://schema.org/PostalAddress'>
      <span itemProp='streetAddress'>
        {data.streetNumber}, {data.streetName}
        {data.streetName &&
          data.unitNumber &&
          `, ${data.unitType} ${data.unitNumber}`}
      </span>
      <br />
      <span itemProp='addressLocality'>{data.city}</span> (
      <span itemProp='addressRegion'>{data.province}</span>){' '}
      <span itemProp='postalCode'>{data.postalCode}</span>
    </p>
  );
};

AddressBlock.propTypes = {
  data: PropTypes.shape({
    streetNumber: PropTypes.string.isRequired,
    streetName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    province: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    unitNumber: PropTypes.string,
    unitType: PropTypes.string,
  }).isRequired,
};

export default AddressBlock;

export const query = graphql`
  fragment AddressBlockData on SanityAddress {
    streetNumber
    streetName
    city
    province
    postalCode
    unitNumber
    unitType
  }
`;
