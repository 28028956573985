// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { List, Line } from './PhoneBlock.styles';

const getPhoneTypeAbbreviation = (type) => {
  let result;

  if (type === 'principal') result = 'T';
  if (type === 'fax') result = 'F';

  return result;
};

const PhoneBlock = ({ data }) => {
  return (
    <List>
      {data.map((phone) => (
        <Line key={phone.number}>
          {getPhoneTypeAbbreviation(phone.type)}{' '}
          <span itemProp={phone.type === 'fax' ? 'faxNumber' : 'telephone'}>
            {phone.number} {phone.post && <span>poste&nbsp;{phone.post}</span>}
          </span>
        </Line>
      ))}
    </List>
  );
};

PhoneBlock.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      post: PropTypes.string,
    })
  ).isRequired,
};

export default PhoneBlock;
