// vendors
import styled from 'styled-components';
import { rem, em } from 'polished';
import Img from 'gatsby-image';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import { fontSizes } from '../../styles/typography';
import responsiveStyle from '../../utils/responsiveStyle';
import breakpointsRange from '../../utils/breakpointsRange';

export const Title = styled.p`
  font-size: ${em(20, fontSizes.body[0])};

  ${responsiveStyle('marginTop', [32, 88], breakpoints.fonts)};

  ${greaterThan(breakpoints.fonts[0])} {
    font-size: ${em(24, fontSizes.body[1])};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${em(30, fontSizes.body[2])};
  }
`;

export const Picture = styled(Img)``;

export const StyledAddress = styled.address`
  margin-bottom: 2em;

  font-style: inherit;
`;

export const PictureWrapper = styled.div`
  position: relative;

  max-width: ${rem(720)};
  max-height: ${rem(480)};
`;

export const StampWrapper = styled.div`
  position: absolute;
  top: 0;

  transform: translateY(-60%);

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [80, 96, 128],
      },
    ],
    breakpoints.fonts
  )};
`;

export const Container = styled.article`
  text-align: center;

  :nth-child(2n + 1) ${StampWrapper} {
    ${breakpointsRange(
      [
        {
          prop: 'left',
          sizes: [28, 80, 80, 120],
        },
      ],
      breakpoints.spacings
    )};
  }

  :nth-child(2n) ${StampWrapper} {
    ${breakpointsRange(
      [
        {
          prop: 'right',
          sizes: [28, 80, 80, 120],
        },
      ],
      breakpoints.spacings
    )};
  }
`;
